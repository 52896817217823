/**
 * log
 * 日志
 */

import request from '@/utils/request'

// 展示
export const logUserList = (data) => {
    return request({
        method: 'post',
        url: '/log/userlist',
        data
    })
}
// 展示
export const logUserList1 = (data) => {
    return request({
        method: 'post',
        url: '/log/userlist1',
        data
    })
}
// 修改
export const logUpdate = (data) => {
    return request({
        method: 'post',
        url: '/log/update',
        data
    })
}